.purchase_text_box {
  border-radius: 10px 0px 0px 10px!important;
  height: 56px;
  width: 220px;
  border-color: coral !important;
}

.apply_button {
  border-radius: 0px 10px 10px 0px !important;
  height: 52px;
  margin-left: -1px;
  width: 100px;
}

.purchase_div {
  width: 75%;
}

.purchase_button {
  height: 56px;
  width: 130px;
  border-radius: 10px;
}


@media(max-width: 1200px) {
  .top-header-text{
    font-size: 20px;
  }
  .top-header {
    height: 6% !important;
  }
  .price {
    font-size: 25px;
  }
}

@media(max-width: 650px) {
  .purchase_text_box {
    border-radius: 10px 0px 0px 10px!important;
    height: 56px;
    width: 100px;
    border-color: coral !important;
  }
  
  .apply_button {
    border-radius: 0px 10px 10px 0px !important;
    height: 52px;
    margin-left: -1px;
    width: 70px;
  }

  .purchase_div {
    width: 50%;
  }

  .purchase_button {
    height: 52px;
    width: 110px;
    border-radius: 10px;
  }
}

