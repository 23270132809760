.mask-box {
    border-radius: 20px;
    position: relative;
    background: #fff;
    height: 48px;
    width: 200px;
    align-items: center;
    /* box-shadow: 1px 1px 2px rgb(165, 165, 165) inset; */
}

.mask {
    width: 100px;
    height: 40px;
    border-radius: 20px;
    background: #d4b55b;
    position: absolute;
    transition: all 0.5s ease;
}

.mask-box .MuiButton-root {
    border-radius: 20px;
    width: 100px;
    height: 40px;
    font-weight: bold;
    transition: all 0.2s 0.1s ease;
  }
  .mask-box .MuiButton-root:hover {
    opacity: 0.8;
  }