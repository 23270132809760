.upgrade {
  padding: 0;
  margin: 0;
  width:100%;
  /* height: 100%; */
  overflow: auto;
  background-color: #fff;
}
/* upgrade card : start */
.cards {
  width: 100%;
  min-height: 1000px;
  display: flex;
  justify-content: flex-end;
}
.mainCard {
  width: 80%;
  justify-content: flex-end;
  display: flex;
  /* height: 100%; */
}
.card_map {
  width: 25%;
  border-left: 1px solid lightgray;
  height:auto;
  text-align: center;
  /* display: flex; */
  flex-direction: column;
  align-items: center;
  /* padding: 10px 0; */
  color: black;
}
.top-header {
  background-color: #007669;
  width: 80%;
  margin: 0 auto ;
  height: 7% !important;
  border-bottom-right-radius: 20px;
  border-bottom-left-radius: 20px;
  justify-content: center;
  text-align: center;
}
.top-header-text {
  color: #fff;
  padding: 15px;
}
.discription {
  font-size: 12px;
}
.thumb-svg {
  margin-right: 5px;
  width: 10px;
}
.price {
  margin: 10px auto;
  color: #d4b55b;
  display: flex;
  align-items: flex-start;
  font-size: 33px;
  font-weight: 600;
}
.month {
  color: black !important;
  margin-left: 2px;
  margin-bottom: 7px;
  align-self: flex-end;
  font-size: 11px;
}
.rupee-svg {
  margin-top: 6px;
  margin-right: 2px;
  width: 10px;
}
.rupee-normal {
  margin-right: 2px;
  font-size: 20px;
}
/* upgrade card : end */
/* upgrade chart : start */
.chart {
  font-size: 14px;
}
.chart_name {
  font-size: 13px;
}
.table tr th {
  color: black !important;
  font-weight: normal;
  font-size: 14px;
}
.table .line:nth-child(odd) {
  background: #f2f6f9 !important;
}
.red {
  color: red !important;
}
.borderless td,
.borderless th {
  border: none !important;
}
/* upgrade chart : end */
.panel-title a:hover {
  color: #007669;
}
.panel-group .panel .panel-heading h4 a:not(.collapsed) .icon_rotate {
  transform: rotate(180deg);
}
.payment-btn:hover {
  background-color: #007669 !important;
}
.icon-overflow {
  position: fixed !important;
  z-index: 99 !important;
}
.top-box {
  margin-bottom: 30px;
  align-items: center;
  justify-content: center;
  text-align: center;
}

@media(max-width: 1200px) {
  .top-header-text{
    font-size: 20px;
  }
  .top-header {
    height: 6% !important;
  }
  .price {
    font-size: 25px;
  }
}

@media(max-width: 650px) {
  .top-header-text{
    font-size: 18px;
    padding: 10px;
  }
  .top-header {
    height: 5% !important;
  }
  .price {
    font-size: 25px;
  }
  .cards {
    width: 140%;
  }
  .tabel-responsive {
    width: 140% !important;
  }
}

