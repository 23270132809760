html {
  background-color: #fff !important;
  color: #fff !important;
  padding: 0 !important;
  margin: 0 !important;
  box-sizing: border-box !important;
}
body {
  font-family: "Noto Sans", sans-serif !important;
}
ul {
  padding: 0;
  margin: 0;
}
li {
  list-style: none;
}
/* -------start header---------*/
.bellazheader {
  background-color: #007669;
}
ul li a {
  font-size: 14px;
  text-transform: uppercase;
}
.bellazheader .MuiSvgIcon-root {
  font-size: 30px;
}
.header-item:hover {
  color: #d4b55b !important;
}
.header-link li:hover {
  background-color: #007669;
}
.header-link li:hover a {
  color: #d4b55b !important;
}
.nav-price {
  margin-left: -30px;
}
.header-item {
  color: #fff !important;
}
.modal {
  margin-top: 100px !important;
  margin-left: 90px !important;
  width: 70% !important;
}
.modal-content {
  padding-top: 20px !important;
}
.navbar-nav li {
  margin-right: 25px;
}
.bellaz-logo {
  width: 150px;
}
.header-btn {
  text-transform: uppercase !important;
  border: none;
  color: #fff !important;
  background-color: #007669;
  border-radius: 20px;
  font-size: 18px;
}
/* -------end header---------*/
/* -------start banner---------*/
.banner-content h6 {
  color: #d4b55b;
}
.store-button {
  width: 75%;
}
.flower-banner {
  width: 20%;
  right: 2%;
  top: 5%;
}
.banner-content {
  padding: 0 20px;
}
.banner-content h1 {
  color: #000;
}
.banner-content p {
  color: gray;
}
.banner-content img {
  width: 50%;
}
/* -------end banner---------*/
/* ------work start ---------*/
.bellazWork-head h1 {
  color: black;
}
.bellazWork-inner .flower-logo {
  left: 10%;
  top: -10%;
  width: 8%;
}
.bellazWork-inner .flower-main {
  right: 5%;
  top: 15%;
}
.location-logo {
  width: 5%;
  fill: #d4b55b !important;
}
.bellazCard-top h2 {
  font-size: 17px;
  color: #d4b55b !important;
}
.bellazWork {
  margin-top: 50px;
  margin-bottom: 100px;
}
.bellazWork-card {
  width: 75%;
  margin-bottom: 40px;
}
.bellazWork-card div {
  padding: 0 30px;
}
.bellazWork-card p {
  text-transform: uppercase;
  color: #d4b55b;
}
.bellazWork-card span {
  color: gray;
  font-size: 14px;
  line-height: 24px;
  padding-left: 10px;
  padding-right: 10px;
}

.bellazTopSalon-card {
  width: 75%;
  margin-bottom: 40px;
}
@media (max-width: 576px) {
  .bellazTopSalon-card {
    width: 85%;
    margin-bottom: 40px;
  }
}
.bellazTopSalon-card img {
  border-top-left-radius: inherit !important;
  border-top-right-radius: inherit !important;
}

.bellazTopSalon-card p {
  text-transform: capitalize;
  font-size: 16px;
  line-height: 22px;
  /* color: #d4b55b; */
}

.bellazTopSalon-card span {
  color: gray;
  font-size: 12px;
  line-height: 16px;
}

.bellazCard-top h1 {
  color: #000;
}
.bellazCard-top {
  position: relative;
  margin-bottom: 75px;
}
/*---------work end ----------*/
/*---------card start ----------*/
.card-hoverImg {
  fill: #fff;
  width: 60px;
}
.cardMain {
  height: 430px;
  transition: 0.4s ease-out;
  position: relative;
}
.cardMain:hover:before {
  opacity: 1;
}
.cardMain:hover .card-content {
  opacity: 1;
  transform: translateY(0px);
}
.cardMain:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 100%;
  height: 100%;
  background: rgba(22, 128, 114, 0.8);
  z-index: 2;
  transition: 0.5s;
  opacity: 0;
}
.cardMain img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
}
.card-content p {
  font-size: 20px;
  letter-spacing: 1px;
  margin-top: 5px;
}
.card-content {
  z-index: 3;
  color: white;
  opacity: 0;
  transform: translateY(10px);
  transition: 0.5s;
}
/*---------card end ----------*/
/* ---------carousal start-------------*/
.carousel-main-head {
  width: 75%;
}
.carousel-outer {
  width: 75% !important;
}
.owl-theme .owl-dots .owl-dot {
  margin-top: 40px;
}
.carousel-card-outer {
  width: 100%;
}
.owl-theme .owl-dots .owl-dot.active span,
.owl-theme .owl-dots .owl-dot:hover span {
  background: #007669 !important;
}
.carousel-card-inner .card-text {
  margin-top: -7px;
}
.carousel-card-inner:hover {
  z-index: 100;
  box-shadow: 2px 2px 15px 0 rgba(135, 135, 135, 0.4);
  transform: translateY(-2px);
}
.carousel-card-inner:hover .btn-card {
  background-color: #d4b55b !important;
}
.carousel-card-inner {
  color: black;
  width: 100%;
  border-radius: 10px !important;
  border: 1px solid lightgray;
  transition: box-shadow 0.2s ease-in-out, transform 0.35s ease-in-out;
}
.carousel-card-inner p {
  line-height: 25px;
}
.business-content h1 {
  color: black;
}
.business-content {
  margin-bottom: 60px;
}
.business-content h6 {
  width: 45%;
}
.business {
  margin-bottom: 80px;
  /* margin-top: 130px; */
}
.flower2 {
  left: 0;
}
.business .flower3 {
  right: 5%;
  width: 8%;
}
.flower4 {
  left: 2%;
  top: 40%;
  width: 9%;
}
.flower5 {
  top: 70%;
  left: 5%;
}
.flower6 {
  right: 5%;
  top: 0;
}
.flower7 {
  width: 8%;
  left: 32%;
  bottom: -10%;
  z-index: 99;
}
.flower8 {
  right: 10%;
}
.flower9 {
  left: 5%;
  top: 75%;
}
.flower10 {
  left: 7%;
  top: 90%;
}
.flower11 {
  left: 1%;
  top: 115%;
  width: 10%;
}
.flower12 {
  right: -3%;
  top: 66%;
  width: 9%;
  z-index: 99;
}
.flower13 {
  right: -5%;
  z-index: 99;
}
.flower14 {
  left: 1%;
  top: 46%;
  width: 9%;
  z-index: 98;
}
.flower15 {
  top: 30%;
  left: 7%;
  z-index: 98;
}
/* -------carousel end --------*/
/* -----------review start-----------*/
#client-testimonial-carousel {
  min-height: 500px;
  background-color: transparent;
}
.bellazReview {
  background-color: #d7f2eb;
}
.review-card-main {
  width: 100%;
  height: 340px;
  padding: 0 10px;
}
.review-card-text {
  font-size: 15px;
  line-height: 30px;
  width: 60%;
  color: black;
}
.review-card-title {
  color: black;
  font-size: 18px;
}
.review-card-avatar {
  width: 55px;
  background-color: gray !important;
  border-radius: 50%;
  padding-top: 6px;
  margin-bottom: 15px;
}
.review-card-avatars {
  width: 60px;
  border-radius: 50%;
  margin-bottom: 15px;
}
.review-card-span {
  font-size: 12px;
  color: gray;
}
.carousel-indicators {
  margin-bottom: 30px;
}
.carousel-indicators li {
  background-color: #d4b55b !important;
  border-radius: 50%;
  width: 8px !important;
  height: 8px !important;
}
.carousel-indicators .active {
  background-color: #007669 !important;
}
/* -----------review end-----------*/
/*-------------download start---------*/
.donwload-banner-logo {
  width: 140px;
  margin-bottom: 10px;
}
.phone-img {
  width: 47%;
  padding-top: 120px;
}
.download-left-content {
  width: 40%;
}
.download-left-content h6 {
  line-height: 25px;
}
.pop {
  color: black;
  margin-bottom: 40px;
}
.pop h1 {
  width: 50%;
}
.pop p {
  line-height: 24px;
  width: 65%;
}
.download-banner-main-last {
  margin-bottom: 120px;
}
.pop-row div {
  font-weight: 600 !important;
}
.pop-row {
  width: 70%;
}
.pop-main {
  margin-left: 30px !important;
  padding: 10px 20px !important;
}
/*---------download end ------------*/
/* -------------category start-------------*/
.category-cards {
  border-radius: 20px !important;
  border: 1px solid lightgray;
  transition: box-shadow 0.2s ease-in-out, transform 0.35s ease-in-out;
}
.bellaz-cat .flower-main2 {
  left: 5%;
  top: 0;
}
.category-content {
  margin-bottom: 100px;
}
.category-cards:hover {
  color: #d4b55b;
  box-shadow: 0 8px 8px 0 rgba(0, 0, 0, 0.1);
  transform: translateY(-5px);
}
.category-cards:hover .card-svg {
  fill: #d4b55b !important;
}
.card-svg {
  fill: #007669;
  width: 27%;
  margin-bottom: 5px;
}
.category-top h1 {
  color: black;
}
.category-top {
  margin-bottom: 50px;
}
.bellaz-cat .led {
  position: absolute;
  top: 20%;
  left: 0;
  z-index: 99;
}
.bellaz-cat .led2 {
  position: absolute;
  right: 0;
  top: 20%;
  z-index: 99;
}
.cat-leaf-right {
  transform: rotate(180deg);
}
/* ------------category end-------------*/
/*-------footer start--------*/
.footer-Main {
  border-top: 1px solid lightgray;
}
.footer-bellaz-logo {
  display: block;
  margin-bottom: 15px;
  width: 140px;
}
.form-header h6 {
  width: 45% !important;
}
.widget-about {
  margin-top: -40px !important;
}
.widget-about ul li i {
  font-size: 20px;
  color: lightgray;
}
.footer-big {
  width: 65%;
  padding-top: 80px;
  padding-bottom: 20px;
}
.footer-menu ul li a {
  color: #898b96;
  font-size: 12px;
  line-height: 25px;
  text-transform: none;
  font-weight: 500;
}
.footer-menu ul li a:hover {
  color: #d4b55b;
}
.footer-widget-title {
  line-height: 18px;
  margin-bottom: 10px;
  font-size: 16px;
  font-weight: 600;
  color: #363940;
}
.mini-footer {
  border-top: 1px solid lightgray;
  padding: 8px 0;
  height: 40px;
  width: 65%;
  text-align: center;
}
.footer-download-btn {
  width: 200px;
  padding: 10px 0 !important;
  font-size: 15px !important;
  background-color: #263940 !important;
}
.copyright-main {
  color: lightgray;
  font-size: 16px;
  font-weight: normal;
}
.copyrightt .MuiSvgIcon-root {
  margin-right: 5px;
  font-size: 18px;
}
.review-card {
  width: 45px;
  height: 45px;
  background-color: transparent;
}
/*-------footer end--------*/
/*----fomr start------*/
.form-leaf-right {
  transform: rotate(180deg);
}
.form-width {
  width: 75%;
}
.review-card-avatars {
  width: 60px;
  height: 60px;
  background-color: transparent;
}
.formss {
  height: 72% !important;
}
.review-left span {
  font-size: 14px;
}
.review-middle {
  width: 50% !important;
}
.form-start {
  margin-bottom: 10px;
}
.form-inner div input {
  border-radius: 12px;
  height: 45px;
}
.form-location p {
  font-size: 12px;
}
.sel-last input::placeholder {
  position: absolute;
  top: 10%;
  font-size: 13px;
}
.sel-last input {
  padding-bottom: 125px !important;
  padding-top: 20px !important;
  border-radius: 20px !important;
}
.form-submit-btn {
  padding: 12px 25px !important;
  border-radius: 20px !important;
  background-color: #d4b55b !important;
  border: none !important;
}
.form-header {
  color: black;
  margin-top: 80px;
}
.form-header h6 {
  width: 65%;
  line-height: 24px;
}
.detail-avatar div {
  width: 80%;
}
/*----fomr end------*/
/*---------last carousel start -----------*/
.owl-nav button {
  position: absolute;
  top: 45%;
  background-color: #000;
  color: #fff;
  transition: all 0.3s ease-in-out;
}
.owl-nav button.owl-prev {
  left: -5%;
}
.owl-nav button.owl-next {
  right: -5%;
}
.Lastcarousel-main-head {
  width: 75%;
}
.lastCarousel-card-inner {
  height: 120px !important;
  background-color: #fff;
  margin-top: -60px !important;
  position: relative;
  z-index: 9;
  border-radius: 10px !important;
  transition: box-shadow 0.3s ease-in-out, transform 0.4s ease-in-out;
}
.lastCarousel-card-inner:hover {
  z-index: 100;
  box-shadow: 9px 5px 27px -8px rgba(0, 0, 0, 0.75);
  transform: translateY(-10px);
}
.lastCarousel-card-inner:hover .lastCarousel-cart-btn {
  display: block !important;
}
.lastCarousel-card-inner:hover h6 {
  margin-bottom: 15px !important;
}
.lastCarousel-card-inner h1 {
  color: black;
  font-size: 18px !important;
  letter-spacing: 0.5px;
  line-height: 25px;
}
.lastCarousel-card-inner h6 {
  color: gray;
  font-size: 14px;
}
.lastCarousel-cart-btn {
  color: black;
}
.owl-nav .owl-prev,
.owl-nav .owl-next {
  background-color: lightgray !important;
  padding: 5px !important;
  width: 52px !important;
  height: 52px !important;
  border-radius: 15px !important;
}
.owl-nav .owl-prev i,
.owl-nav .owl-next i {
  font-size: 24px;
  color: #fff;
}
/*--------last carousel end-------------*/
.lastBanner-Main {
  margin-top: 80px;
  margin-bottom: 90px;
}
.lastbanner-inner {
  background-color: #d5b55c;
  border-radius: 20px;
  color: #fff !important;
  width: 80%;
}
.lastbanner-inner-main p {
  width: 80%;
}
.lastbanner-inner-main {
  padding: 50px !important;
}
@media (max-width: 576px) {
  .banner-main {
    margin-top: 30px !important;
  }
  .store-button {
    width: 60% !important;
  }

  .banner-content img {
    width: 70% !important;
  }
  .banner-content {
    padding: 0 !important;
  }
  .pop-row {
    width: 100% !important;
  }
  .carousel-main-head-inner {
    width: 100% !important;
  }
  .navbar-nav li {
    margin-right: 5px !important;
  }
  .category-content {
    margin-bottom: 30px !important;
  }
  .header-btn {
    margin-top: 5px !important;
    margin-bottom: 25px !important;
  }
  .banner-person-img {
    display: none;
  }
  .carousel-outer {
    width: 100% !important;
  }
  .carousel-card-inner {
    width: 80% !important;
  }
  .banner-content h6 {
    margin-bottom: 20px !important;
  }
  .footer-menu img {
    width: 45% !important;
  }
  .banner-content h1 {
    margin-bottom: 30px !important;
  }
  .banner-content p {
    margin-bottom: 30px !important;
  }
  .bellazWork-card div {
    margin-bottom: 25px !important;
  }
  .bellazWork-btn {
    font-size: 12px !important;
  }
  .review-card-avatars {
    width: 50px;
    height: 50px;
  }
  .bellazWork-card {
    width: 90% !important;
    margin-bottom: 0 !important;
  }
  .category-cards span {
    font-size: 12px !important;
    font-weight: bolder;
  }
  .category-cards {
    margin-bottom: 10px !important;
  }
  .card-overlay-main {
    width: 100% !important;
  }
  .apple-btn {
    margin-bottom: 15px !important;
  }
  .card-btn-overlay {
    font-size: 7px !important;
  }
  .card-img-overlay {
    width: 100% !important;
  }
  .review-card-text {
    width: 100% !important;
    font-size: 11.5px !important;
    line-height: unset !important;
  }
  .bellazWork-card img {
    width: 50% !important;
  }
  .bellazCard-top img {
    width: 25% !important;
    margin-left: 0 !important;
    margin-bottom: 30px !important;
  }
  .header-link li {
    width: 100% !important;
  }
  .navbar-nav li a {
    color: black !important;
  }
  .lo {
    padding: 0 30px !important;
  }
  .location-logo {
    display: none;
  }
  .downalod-upper {
    padding: 0 10px !important;
  }
  .category-top {
    padding: 0 20px !important;
  }
  .card-content p {
    font-size: 9px !important;
  }
  .card-hoverImg {
    width: 40% !important;
  }
  .cardMain {
    height: 180px !important;
  }
  .donwload-banner {
    height: 400px !important;
  }
  .download-left-content {
    width: 90% !important;
    margin-bottom: 10px !important;
    align-self: baseline;
  }
  .download-left-content h1 {
    font-size: 25px !important;
  }
  .download-left-content h6 {
    font-size: 14px !important;
    line-height: 20px !important;
  }
  .card-img-overlay {
    flex-direction: column !important;
  }
  .download-bg {
    height: 460px;
  }
  .bellazCard-top {
    margin-bottom: 30px !important;
  }
  .phone-img {
    padding-top: 0 !important;
    width: 350px !important;
    align-self: baseline;
  }
  .app {
    width: 90% !important;
  }
  .bellazWork {
    margin-top: 40px !important;
    margin-bottom: 30px !important;
  }
  .business-content {
    padding: 0 10px !important;
    margin-bottom: 30px !important;
  }
  .business {
    margin-bottom: 20px !important;
    margin-top: 30px !important;
  }
  .carousel-indicators {
    margin-top: 40px;
    margin-bottom: 10px !important;
  }
  #client-testimonial-carousel {
    min-height: 520px;
    background-color: transparent;
  }
  .carousel-main-head {
    width: 100% !important;
  }
  .owl-theme .owl-dots .owl-dot {
    margin-top: 20px !important;
  }
  .review-card-main {
    height: 400px;
    padding: 0 2px;
  }
  .owl-nav .owl-prev,
  .owl-nav .owl-next {
    padding: 3px !important;
    width: 44px !important;
    height: 44px !important;
  }
  .owl-nav button.owl-prev {
    left: 0;
    top: 85%;
  }
  .owl-nav button.owl-next {
    right: 0;
    top: 85%;
  }
  .lastbanner-inner {
    width: 90% !important;
  }
  .lastBanner-Main {
    margin-top: 40px !important;
    margin-bottom: 40px !important;
  }
  .review-card-text {
    width: 100% !important;
    font-size: 15px !important;
  }
  .form-header {
    margin-bottom: 30px !important;
    margin-top: 30px !important;
  }
  .formss-outer {
    width: 100% !important;
  }
  .formss {
    height: 40% !important;
  }
  .Lastcarousel-main-head {
    width: 100% !important;
  }
  .detail-avatar div {
    width: 100% !important;
  }
  .bellaz-cat {
    margin-bottom: 40px !important;
  }
  .form-start {
    margin-top: 30px !important;
  }
  .download-banner-main-last {
    margin-bottom: 70px !important;
  }
  .mini-footer {
    width: 100% !important;
  }
  .footer-big {
    width: 100% !important;
  }
  .pop {
    margin-bottom: 10px;
  }
  .pop h1 {
    width: 80%;
  }
  .pop p {
    line-height: 20px;
    width: 90%;
  }
}
@media (max-width: 768px) {
  .category-top {
    margin-bottom: 50px !important;
  }
  .see img {
    display: none;
  }
  .download-left-content {
    padding-top: 0 !important;
    width: 90% !important;
  }
  .download-left-content h1 {
    font-size: 18px !important;
  }
  .download-left-content h6 {
    font-size: 12px !important;
    line-height: 15px !important;
  }
  .see .pppp {
    padding-left: 50px !important;
  }
  .lastbanner-inner-main {
    padding: 30px !important;
  }
  .lastbanner-inner-main p {
    width: 100%;
  }
  .header-item {
    color: #000 !important;
  }
  .header-btn {
    margin-bottom: 15px;
  }
  .navbar-item {
    width: 100%;
    margin-bottom: 5px;
    align-self: center;
    display: flex;
    justify-content: center;
  }
  .form-remove-img .remove-img {
    display: none !important;
  }
  .category-row img {
    display: none;
  }
  .detail-avatar div {
    width: 75% !important;
  }
  .donwload-banner-logo {
    width: 38%;
  }
  .form-width {
    width: 100% !important;
  }
  .bellazWork-card span {
    padding: 0 0 !important;
  }
  .bellazWork-card div {
    padding: 0 8px !important;
  }
  .form-header h6 {
    width: 90% !important;
    line-height: 20px !important;
  }
  .pop h1 {
    width: 100%;
  }
  .business-content h6 {
    width: 100%;
  }
  .carousel-outer {
    width: 100% !important;
  }
  .cardMain {
    height: 250px !important;
  }
  .review-card-text {
    line-height: 24px;
    width: 80%;
  }
}
@media (max-width: 970px) {
  .form-width {
    width: 100% !important;
  }
  .bellazWork-card {
    width: 90% !important;
  }
  .pop h1 {
    width: 90% !important;
  }
  .lastbanner-inner-main {
    padding: 30px !important;
  }
  .lastbanner-inner-main p {
    width: 100%;
  }
  .download-left-content {
    width: 50%;
  }
  .phone-img {
    padding-top: 50px;
  }
  .download-left-content h6 {
    line-height: 20px;
  }
  .form-header {
    margin-bottom: 40px !important;
  }
  .form-submit-btn {
    padding: 8px 15px !important;
  }
  .pop-row {
    width: 100%;
  }
  .footer-big {
    width: 85%;
  }
  .mini-footer {
    width: 85%;
  }
}

.icon {
  padding-left: 25px;
  background: url("../../_metronic/_assets/icons/locationIcon.png") no-repeat
    left;
}

.filterIcon {
  padding: 25px;
  background: url("../../_metronic/_assets/icons/filterByIcon.png") no-repeat
    left;
}

.form-select {
  display: block;
  /* width: 100%; */
  padding: 0.65rem 3rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  background-color: #ffffff;
  background-clip: padding-box;
  border: 1px solid #e4e6ef;
  border-radius: 1.42rem;
  box-shadow: none;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.form-select1 {
  display: block;
  /* width: 100%; */
  padding: 0.65rem 1rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  background-color: #ffffff;
  background-clip: padding-box;
  border: 1px solid #e4e6ef;
  border-radius: 1.42rem;
  box-shadow: none;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.form-select:focus-visible {
  outline: none !important;
  border: 2px solid #e4e6ef !important;
  border-radius: 1.42rem !important;
}
.form-select1:focus-visible {
  outline: none !important;
  border: 2px solid #e4e6ef !important;
  border-radius: 1.42rem !important;
}

.centerContent {
  display: flex;
  align-items: center;
  justify-content: center;
}

.marginTop180 {
  margin-top: 180px !important;
}

.textWrapper {
  display: -webkit-box;
  /* max-width: 400px; */
  height: 100px; /* Fallback for non-webkit */
  margin: 0 auto;
  font-size: 14px;
  /* line-height: $line-height; */
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.textWrapper span {
  display: -webkit-box;
  /* max-width: 400px; */
  height: 100px; /* Fallback for non-webkit */
  margin: 0 auto;
  font-size: 14px;
  /* line-height: $line-height; */
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.allSalonImage {
  border-top-left-radius: inherit;
  border-bottom-left-radius: inherit;
}

@media (max-width: 768px) {
  .allSalonImage {
    border-top-left-radius: inherit;
    border-top-right-radius: inherit;
    border-bottom-left-radius: initial;
  }
}
